import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/ga.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/google/gtm.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@next/third-parties/dist/ThirdPartyScriptEmbed.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/link.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/shared/lib/lazy-dynamic/dynamic-bailout-to-csr.js");
import(/* webpackMode: "eager" */ "/vercel/path0/src/common/components/UiComponent/CustomCollapse.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/common/Buttons/InputOutputActionButton.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/common/HandleFullScreen/HandleFullScreen.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/common/HtmlTags/H2Tag/H2Tag.module.css");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/common/HtmlTags/H1Tag/H1Tag.module.css");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/common/HtmlTags/PTag/PTag.module.css");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/common/Ide/Ide.module.css");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/Donate/DonationCheckout/DonationCheckout.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/PrivacyPolicy/PrivacyPolicy.module.css");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/tools/AsciiConversion/StringToAscii/StringToAscii.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/tools/Diff/JSONDiff/JSONDiff.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/tools/helper/CopyToClip/CopyToClip.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/tools/helper/InputOutputViewer/InputOutputViewer.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/tools/helper/JsonViewer/JsonViewer.module.css");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/tools/helper/ToolOutputActions/ToolOutputActions.module.css");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/tools/JsonParser/JsonParser.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/tools/JsonTools/JSONMinify/JSONMinify.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/tools/JsonTools/JSONToTypescript/JSONToTypescript.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/tools/JsonTools/JSONToXML/JSONToXML.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/tools/JsonToString/JsonToString.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/tools/NumbersTools/NumbersToWords/NumbersToWords.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/tools/ReplaceSpaces/ReplaceSpaces.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/tools/SalaryHikePercentageCalculator/HikeInPercentageBySalary.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/tools/SalaryHikePercentageCalculator/NewSalaryByPercentage.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/tools/SalaryHikePercentageCalculator/SalaryHikePercentageCalculator.module.css");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/tools/TextTools/RandomStringGenerator/RandomStringGenerator.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/tools/TextTools/RemoveExtraSpaces/RemoveExtraSpaces.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/tools/TextTools/RemoveSpaces/RemoveSpaces.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/tools/ToLowercase/ToLowercase.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/tools/ToolsList/ToolsList.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/tools/ToUppercase/ToUppercase.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/tools/URLDecode/URLDecode.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/tools/URLEncode/URLEncode.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/tools/UUIDGenerator/UUIDGenerator.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/tools/WordCounter/WordCounter.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/tools/XmlTools/XmlFormatter/XmlFormatter.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/tools/XmlTools/XmlMinifier/XmlMinifier.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/components/tools/XmlTools/XMLToJSON/XMLToJSON.tsx");
import(/* webpackMode: "eager" */ "/vercel/path0/src/hooks/useGetUrl.ts")